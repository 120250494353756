import * as React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/Layout'

import SideNavigation from 'components/sections/sideNav/Navigation'
import SideNavSection from 'components/sections/sideNav'
import Seo from '../components/Seo'
import { sideNavData } from 'types'

const SideNavMenu = () => {
  const sideNavInfo: sideNavData = {
    title: '',
    links: [
      // { title: 'Home', id: '#index' },
    ],
  }

  return <SideNavigation sideNavInfo={sideNavInfo} />
}

const SideNavMain = () => {
  return (
    <>
      <div id="overview" className="jumptarget mt-50 mb-50">
        <h1>Page not found</h1>
        <p className="mt-8.5">
          Sorry this page has moved, doesn&apos;t exist or is in superposition.
        </p>
        <p>
          Please head back to the <Link to="/">Xanadu homepage</Link>.
        </p>
      </div>
    </>
  )
}

const Page404 = () => {
  return (
    <Layout>
      <SideNavSection nav={<SideNavMenu />} main={<SideNavMain />} />
    </Layout>
  )
}

export default Page404

export const Head = () => {
  return (
    <Seo
      title="Not Found"
      description="Xanadu's mission is to build quantum computers that are useful and available to people everywhere."
    />
  )
}
